import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../layout";
import Ticker from "../components/Ticker";
import Carousel from "../components/Carousel";
import "normalize.css/normalize.css";
import "../styles/reset.scss";

const IndexPage = () => {
  // Main data query - passed to Carousel - Slider - Modal components.
  const projects = useStaticQuery(graphql`
    query getProjects {
      allContentfulProject(sort: { fields: contentfulid, order: ASC }) {
        edges {
          node {
            contentful_id
            title
            tag
            modalHeadings
            role
            team
            description {
              description
            }
            images {
              id
              filename
              gatsbyImageData(placeholder: NONE)
            }
            miscellaneous {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);

  // Maps direcetly via GraphQl Contentful projects.
  const nodes = projects.allContentfulProject.edges;
  const displayProjects = nodes.map((element, index) => {
    return (
      <Carousel key={element.node.contentful_id} projectData={element.node} />
    );
  });

  return (
    <Layout>
      <header>
        <h1>A selection of recent work by designer Natalie Hopson.</h1>
      </header>
      <Ticker />
      <section className="section-carousel"></section>
      {displayProjects}
    </Layout>
  );
};

export default IndexPage;
export const Head = () => <title>Home Page</title>;
