import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { GatsbyImage } from "gatsby-plugin-image"
import 'bootstrap/dist/css/bootstrap.min.css';
import './slider.scss';

const Slider = ({images, shareIndex}) => {
  const [index, setIndex] = useState(0)

  // Updates index shown to user via each carousel swipe/slide.
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    shareIndex(selectedIndex);
  };

  // Use styling here to sugest exact image size and styling.
  const displayImages = images.map((element, index) => {
    return (
      <Carousel.Item key={index}>
      <GatsbyImage
      image={element.gatsbyImageData}
      alt={element.filename}
      objectFit="contain"
      ></GatsbyImage>
      </Carousel.Item>
    )
  })

  return (
    <Carousel 
    activeIndex={index} 
    onSelect={handleSelect}
    variant="dark"
    interval={null}
    indicators={false}
    touch={true}>
    {displayImages}
    </Carousel>
  )
}

export default Slider;