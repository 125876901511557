import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "./Slider";
import Modal from "./Modal";
import "./carousel.scss";

const Carousel = ({ projectData }) => {
  const [isHidden, setIsHidden] = useState(true);
  const [imageIndex, setImageIndex] = useState(1);

  const handleModal = (e) => {
    if (isHidden) {
      return setIsHidden(false);
    }
    setIsHidden(true);
  };

  // This index is displayed to the user. Important it stays here.
  const handleIndex = (num) => setImageIndex(num + 1);

  return (
    <motion.div
      className="section--container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      <div className="carousel--container">
        <Slider images={projectData.images} shareIndex={handleIndex} />
        <AnimatePresence>
          {!isHidden && (
            <Modal
              closeModal={handleModal}
              description={projectData.description.description}
              columnOne={projectData.team}
              columnTwo={projectData.role}
              misc={projectData.miscellaneous}
              headings={projectData.modalHeadings}
            />
          )}
        </AnimatePresence>
      </div>
      <div className="tags-container">
        <div className="tags-left">
          <span className="tags-title">{projectData.title}</span>
          <span className="italic-tags">{projectData.tag}</span>
        </div>
        <div className="tags-right">
          <span className="counter">
            {imageIndex}/{projectData.images.length}
          </span>
          <button className="modal-toggle" onClick={(e) => handleModal(e)}>
              {isHidden ? "Read more" : "Exit modal"}
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default Carousel;
