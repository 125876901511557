import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby"
import "./ticker.scss";

const Ticker = () => {
  const data = useStaticQuery(graphql`
  query getTickerContent {
    contentfulTicker {
      id
      tickerList
    }
  }`)
  
  const [state, setState] = useState({
    count: 0,
    id: 0,
    tickerItems: data.contentfulTicker.tickerList
  });

  useEffect(() => {
    const tickerCount = () => {
      const count = state.count + 1;

      if (state.count < state.tickerItems.length - 1) {
        return setState((prev) => ({ ...prev, count }));
      }

      setState((prev) => ({ ...prev, count: 0 }));
    };

    setTimeout(() => {
      tickerCount();
    }, 500);
  }, [state.count, state.tickerItems.length]);

  return (
    <section className="ticker-section">
      <p className="ticker-p">She does</p>
      <p className="ticker-item">{state.tickerItems[state.count]}.</p>
    </section>
  );
};

export default Ticker;