import React from "react";
import { motion } from "framer-motion";
import CloseButton from "react-bootstrap/CloseButton";
import "./modal.scss";

const Modal = ({ closeModal, description, columnOne, columnTwo, misc, headings }) => {
  const handleClick = (e) => {
    e.preventDefault();
    closeModal();
  };

  const listItems = (items) => {
    return items.map((element, index) => <li key={index}>{element}</li>)
  };

  return (
    <motion.div
      className="modal-outline"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="modal-guts">
        <div className="topBar">
          <CloseButton variant="white" onClick={(e) => handleClick(e)} active />
        </div>

        <div className="modal-contentWrapper">
          <div className="modal-heading">
            <p className="modal-description">{description}</p>
          </div>

          <div className="modal-details-container">
            <div className="modal-list">
              <h3 className="list-heading">{headings[0]}</h3>
              <ul>{columnOne ? listItems(columnOne) : null}</ul>
            </div>
            <div className="modal-list">
              <h3 className="list-heading">{headings[1]}</h3>
              <ul>{columnTwo ? listItems(columnTwo) : null}</ul>
            </div>
            <div className="modal-list">
              <h3 className="list-heading">{headings[2]}</h3>
              {misc !== null && (
                <div 
                  dangerouslySetInnerHTML={{
                    __html: misc.childMarkdownRemark.html,
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Modal;
